import {
  GET_STORIES,
  GET_STORIES_FAILED,
  GET_STORIES_SUCCESS,
  UPDATE_STORY,
  DELETE_STORY,
  FILTER_STORIES,
  RESET,
} from './types';

export const getStories = (data) => ({
  type: GET_STORIES,
  data,
});

export const getStoriesFailed = (data) => ({
  type: GET_STORIES_FAILED,
  data,
});

export const getStoriesSucces = (data) => ({
  type: GET_STORIES_SUCCESS,
  data,
});

export const updateStory = (data) => ({
  type: UPDATE_STORY,
  data,
});

export const deleteStory = (id, setModal) => ({
  type: DELETE_STORY,
  id,
  setModal,
});

export const filterStories = (data) => ({
  type: FILTER_STORIES,
  data,
});

export const reset = () => ({
  type: RESET,
});
