import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';
import { push } from 'connected-react-router';

// utils
import XHR from '../../../utils/XHR';
import {
  addEventSuccess,
  addEventFailure,
  addEventGallary as addEventGallaryRequest,
  getEventsKindsSuccess,
} from './actions';
import { getEventById } from '../../Events/redux/actions';
import {
  ADD_EVENT_REQUEST,
  ADD_EVENT_GALLARY,
  DELETE_EVENT_GALLARY,
  UPDATE_EVENTS,
  GET_EVENT_KINDS,
} from './types';

//Navigation

function addEventAPI(data) {
  const URL = `${BASE_URL}/event/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* addEventRequest({ data, eventsImage }) {
  try {
    const response = yield call(addEventAPI, data);
    if (response?.data) {
      if (eventsImage) {
        eventsImage(response?.data?.id);
      } else {
        yield put(addEventSuccess(response.data));
        yield put(
          push({
            pathname: '/admin/events',
          })
        );
      }
    }
  } catch (e) {
    const { response } = e;
    yield put(addEventFailure(response?.data));
  }
}

function addEventGallaryAPI(data) {
  const URL = `${BASE_URL}/event-gallery/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* addEventGallary({ data }) {
  try {
    const response = yield call(addEventGallaryAPI, data);
    yield put(addEventSuccess(response.data));
    yield put(
      push({
        pathname: '/admin/events',
      })
    );
  } catch (e) {
    const { response } = e;
    yield put(addEventFailure(response?.data));
  }
}

function deleteEventGallaryAPI(id) {
  const URL = `${BASE_URL}/event-gallery/${id}/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

function* deleteEventGallary({ id, eventId }) {
  try {
    const response = yield call(deleteEventGallaryAPI, id);
    yield put(getEventById(eventId));
  } catch (e) {
    const { response } = e;
    yield put(addEventFailure(response?.data));
  }
}

async function editEventsAPI(data, id) {
  const URL = `${BASE_URL}/event/${id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data,
  };
  return XHR(URL, options);
}

function* editEvent({ data, id, addImageGallary }) {
  try {
    const response = yield call(editEventsAPI, data, id);
    if (response.data) {
      if (addImageGallary) {
        addImageGallary(response.data.id);
      } else {
        yield put(addEventSuccess(response.data));
        yield put(
          push({
            pathname: '/admin/events',
          })
        );
      }
    }
  } catch (e) {
    const { response } = e;
    yield put(addEventFailure(response?.data));
  }
}

function getEventsKindsAPI() {
  const URL = `${BASE_URL}/event-type/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getEventsKinds() {
  try {
    const response = yield call(getEventsKindsAPI);
    yield put(getEventsKindsSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getEventsKindsSuccess(response?.data));
  }
}

export default all([
  takeLatest(ADD_EVENT_REQUEST, addEventRequest),
  takeLatest(ADD_EVENT_GALLARY, addEventGallary),
  takeLatest(DELETE_EVENT_GALLARY, deleteEventGallary),
  takeLatest(UPDATE_EVENTS, editEvent),
  takeLatest(GET_EVENT_KINDS, getEventsKinds),
]);
