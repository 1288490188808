//Images
import businessIcon from './assets/img/business.png';
import creditCardIcon from './assets/img/creditCard.png';
import eventsIcon from './assets/img/events.png';
import settingsIcon from './assets/img/settingsIcon.png';
import storiesIcon from './assets/img/stories.png';

import loadable from '@loadable/component';

const Login1 = loadable(() => import('./Containers/Login'));
const Signup1 = loadable(() => import('Containers/SignUp'));
const Verification = loadable(() => import('Containers/Verification'));
const ForgetPassword = loadable(() => import('Containers/ForgetPassword'));
const UpdatePassword = loadable(() => import('Containers/UpdatePassword'));
const PrivacyPolicy = loadable(() => import('Containers/PrivacyPolicy'));
const TermsConditions = loadable(() => import('Containers/TermsConditions'));
const SelectPlan = loadable(() => import('Containers/SelectPlan'));
const BasicPackage = loadable(() => import('Containers/BasicPackage'));
const PremiumPackage = loadable(() => import('Containers/PremiumPackage'));
const AddBusiness = loadable(() => import('Containers/AddBussines'));
const AccountSetting = loadable(() => import('Containers/AccountSetting'));


const AddEvent = loadable(() => import('Containers/AddEvent'));
const AddStory = loadable(() => import('Containers/AddStory'));
const Business = loadable(() => import('Containers/Business'));
const CardList = loadable(() => import('Containers/CardList'));
const EditEvent = loadable(() => import('Containers/EditEvent'));
const Events = loadable(() => import('Containers/Events'));
const Stories = loadable(() => import('Containers/Stories'));

const routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'nc-icon nc-bank',
    component: Login1,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/addBusiness',
    name: 'Add  Business',
    icon: 'nc-icon nc-bank',
    component: AddBusiness,
    layout: '/admin',
    isShow: 1,
  },
  {
    path: '/addEvent',
    name: 'Add  Event',
    icon: 'nc-icon nc-bank',
    component: AddEvent,
    layout: '/admin',
    isShow: 1,
  },
  {
    path: '/addStory',
    name: 'Add  Story',
    icon: 'nc-icon nc-bank',
    component: AddStory,
    layout: '/admin',
    isShow: 1,
  },
  {
    path: '/editEvent',
    name: 'Edit  Event',
    icon: 'nc-icon nc-bank',
    component: EditEvent,
    layout: '/admin',
    isShow: 1,
  },
  {
    path: '/package',
    name: 'Basic Package',
    icon: 'nc-icon nc-bank',
    component: BasicPackage,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/selectPlan',
    name: 'Select Plan',
    icon: 'nc-icon nc-bank',
    component: SelectPlan,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/premiumPackage',
    name: 'Premium Package',
    icon: 'nc-icon nc-bank',
    component: PremiumPackage,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/business',
    name: 'Businesses',
    icon: businessIcon,
    component: Business,
    layout: '/admin',
  },
  {
    path: '/events',
    name: 'Events',
    icon: eventsIcon,
    component: Events,
    layout: '/admin',
  },
  {
    path: '/stories',
    name: 'Stories',
    icon: storiesIcon,
    component: Stories,
    layout: '/admin',
  },
  {
    path: '/CardList',
    name: 'Cards List',
    icon: creditCardIcon,
    component: CardList,
    layout: '/admin',
  },
  // {
  //   path: '/privacyPolicy',
  //   name: 'Privacy Policy',
  //   icon: privacyPolicyIcon,
  //   component: PrivacyPolicy,
  //   layout: '/admin',
  // },
  {
    path: '/updatepassword',
    name: 'Update Password',
    icon: 'nc-icon nc-bank',
    component: UpdatePassword,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/forgetpassword',
    name: 'Forget Password',
    icon: 'nc-icon nc-bank',
    component: ForgetPassword,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/verification',
    name: 'Verification',
    icon: 'nc-icon nc-bank',
    component: Verification,
    layout: '/auth',
    isShow: 1,
  },
  {
    path: '/signup',
    name: 'Signup',
    icon: 'nc-icon nc-bank',
    component: Signup1,
    layout: '/auth',
    isShow: 1,
  },
  // S Routes

  // {
  //   path: '/termsConditions',
  //   name: 'Terms & Conditions',
  //   icon: termsConditionsIcon,
  //   component: TermsConditions,
  //   layout: '/admin',
  // },
  // {
  //   path: '/AccountSetting',
  //   name: 'Account Setting',
  //   icon: settingsIcon,
  //   component: AccountSetting,
  //   layout: '/admin',
  // },
  {
    collapse: true,
    name: 'Settings',
    icon: settingsIcon,
    state: 'componentsCollapse',
    views: [
      {
        path: '/AccountSetting',
        name: 'Account Setting',
        mini: '•',
        component: AccountSetting,
        layout: '/admin',
      },
      {
        path: '/termsConditions',
        name: 'Terms & Conditions',
        mini: '•',
        component: TermsConditions,
        layout: '/admin',
      },
      {
        path: '/privacyPolicy',
        name: 'Privacy Policy',
        mini: '•',
        component: PrivacyPolicy,
        layout: '/admin',
      },
      // {
      //   path: '/business',
      //   name: 'Log Out',
      //   mini: '•',
      //   // component: PrivacyPolicy,
      //   layout: '/admin',
      // },
    ],
  },
];

export default routes;
