import { all, call, put, delay, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import { push } from 'connected-react-router';
// config
import { BASE_URL } from '../../../config/app';
// utils
import XHR from '../../../utils/XHR';

//Navigation

// types
import { GET_PACKAGE, CREATE_SUBSCRIPTION_REQUEST } from './types';

//action
import { getPackageFailed, getPackageSuccess, createSubscriptionRequestSuccess } from './actions';

async function getPackageApi() {
  const URL = `${BASE_URL}/stripe/list_plans/`;
  const accessToken = await localStorage.getItem('accessToken');
  const token = await localStorage.getItem('token');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken ? accessToken : token}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getPackage() {
  try {
    const response = yield call(getPackageApi);
    yield put(getPackageSuccess(response.data.data));
  } catch (e) {
    const { response } = e;
    yield put(getPackageFailed(response.data));
  }
}

async function createSubscriptionApi(data) {
  const URL = `${BASE_URL}/stripe/create_subscription/`;
  const accessToken = await localStorage.getItem('token');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* createSubscription({ data }) {
  try {
    const response = yield call(createSubscriptionApi, data);

    localStorage.getItem('token').then((token) => {
      localStorage.setItem('accessToken', token);
    });
    yield delay(2000);
    localStorage.removeItem('token');
    yield put(
      push({
        pathname: '/business',
      })
    );

    // yield put(createSubscriptionRequestSuccess(response.data))
    // yield put(
    //   push({
    //     pathname: '/basicPackage',
    //   })
    // );
  } catch (e) {
    const { response } = e;
  }
}

export default all([
  takeLatest(GET_PACKAGE, getPackage),
  takeLatest(CREATE_SUBSCRIPTION_REQUEST, createSubscription),
]);
