import {
  ADD_STORY_REQUEST,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAILURE,
  UPDATE_STORY_REQUEST,
  ADD_STORY_GALLARY,
  GET_STORY_BY_ID_REQUEST,
  UPDATE_STORY_GALLARY,
  DELETE_STORY_GALLARY,
  GET_STORY_TYPE,
  GET_STORY_TYPE_SUCCESS
} from './types';

export const addStoryRequest = (data, addStoryImages) => ({
  type: ADD_STORY_REQUEST,
  data,
  addStoryImages
});

export const updateStoryRequest = (data,id, addImageGallary) => ({
  type: UPDATE_STORY_REQUEST,
  data,
  id,
  addImageGallary
});

export const getStoryByIdRequest = (id) => ({
  type: GET_STORY_BY_ID_REQUEST,
  id
});

export const addStorySuccess = (data) => ({
  type: ADD_STORY_SUCCESS,
  data
});

export const getStoryType = () => ({
  type: GET_STORY_TYPE,
});

export const getStoryTypeSuccess = (data) => ({
  type: GET_STORY_TYPE_SUCCESS,
  data
});

export const addStoryGallary = (data) => ({
  type: ADD_STORY_GALLARY,
  data
});


export const deleteStoryGallary = (id, eventId) => ({
  type: DELETE_STORY_GALLARY,
  id,
  eventId
});


export const addStoryFailure = (error) => ({
  type: ADD_STORY_FAILURE,
  error
});

