import {
  GET_PACKAGE,
  GET_PACKAGE_FAILED,
  GET_PACKAGE_SUCCESS,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_REQUEST_SUCCESS
} from './types';

export const getPackage = () => ({
  type: GET_PACKAGE
});

export const createSubscriptionRequest = (data) => ({
  type: CREATE_SUBSCRIPTION_REQUEST,
  data
});

export const createSubscriptionRequestSuccess = () => ({
  type: CREATE_SUBSCRIPTION_REQUEST_SUCCESS,
});

export const getPackageFailed = (error) => ({
  type: GET_PACKAGE_FAILED,
  error,
});

export const getPackageSuccess = (data) => ({
  type: GET_PACKAGE_SUCCESS,
  data,
});