import { all, call, put, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import { push } from 'connected-react-router';
// config
import { BASE_URL } from '../../../config/app';
// utils
import XHR from '../../../utils/XHR';

//Navigation

// types
import { UPDATE_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS, CHANGE_SUBSCRIPTION } from './types';

//action
import {
  changeSubscriptioFailed,
  changeSubscriptionSuccess,
  getAccountFailed,
  getAccountSuccess,
  getAccountDetails as getAccountDetails1,
} from './actions';

async function updateAccountApi(data, id) {
  const URL = `${BASE_URL}/saleperson_account/${id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data,
  };
  return XHR(URL, options);
}

async function changeSubscriptionAPI(data) {
  const URL = `${BASE_URL}/stripe/change_subscription/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* changeSubscription({ data, modalState, cardModalState }) {
  try {
    const response = yield call(changeSubscriptionAPI, data);

    yield put(changeSubscriptionSuccess());
    modalState(false);
    yield put(getAccountDetails1());
  } catch (e) {
    const { response } = e;
    if (response.data === 'No Card Found, Please Add Card') {
      cardModalState(true);
      yield put(changeSubscriptioFailed(false));

    } else {
      yield put(changeSubscriptioFailed(response.data));
    }
  }
}

function* updateAccount({ data, id, setShowEdit }) {
  try {
    const response = yield call(updateAccountApi, data, id);
    yield put(getAccountSuccess(response.data));
    setShowEdit(false);
    yield put(getAccountDetails1());

  } catch (e) {
    const { response } = e;
    yield put(getAccountFailed(response.data.message));
  }
}

async function getAccountDetailsApi() {
  const URL = `${BASE_URL}/saleperson_account/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getAccountDetails() {
  try {
    const response = yield call(getAccountDetailsApi);
    yield put(getAccountSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getAccountFailed(response?.data?.message));
  }
}

export default all([
  takeLatest(UPDATE_ACCOUNT_DETAILS, updateAccount),
  takeLatest(GET_ACCOUNT_DETAILS, getAccountDetails),
  takeLatest(CHANGE_SUBSCRIPTION, changeSubscription),
]);
