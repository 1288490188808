import {
  GET_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_FAILED,
  CHANGE_SUBSCRIPTION_SUCCESS,
} from './types';

export const getAccountDetails = () => ({
  type: GET_ACCOUNT_DETAILS,
});

export const getAccountSuccess = (data) => ({
  type: GET_ACCOUNT_SUCCESS,
  data,
});

export const getAccountFailed = (error) => ({
  type: GET_ACCOUNT_FAILED,
  error,
});

export const updateAccountRequest = (data, id, setShowEdit) => ({
  type: UPDATE_ACCOUNT_DETAILS,
  data,
  id,
  setShowEdit,
});

export const changeSubscription = (data, modalState, cardModalState) => ({
  type: CHANGE_SUBSCRIPTION,
  data,
  modalState,
  cardModalState,
});

export const changeSubscriptionSuccess = () => ({
  type: CHANGE_SUBSCRIPTION_SUCCESS,
});

export const changeSubscriptioFailed = (data) => ({
  type: CHANGE_SUBSCRIPTION_FAILED,
  data,
});
