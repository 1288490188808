import { all, call, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';

// utils
import XHR from '../../../utils/XHR';

//Navigation

// types
import { GET_SERVICES } from './types';
//action
import { getServicesSuccess } from './actions';

async function getServicesAPI() {
  const URL = `${BASE_URL}/services/`;
  const accessToken = await localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getServices() {
  try {
    const response = yield call(getServicesAPI);
   
    yield put(getServicesSuccess(response.data));
  } catch (e) {
    const { response } = e;
  }
}

export default all([takeLatest(GET_SERVICES, getServices)]);
