import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNUP,
  LOGIN_FAILED,
  RESET_MESSAGE,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  VERIFICATION,
  VERIFICATION_FAILED,
  VERIFICATION_SUCCESS,
  UPDATE_PASSWORD_RESET,
  SIGNUP_RESET_MESSAGE,
  PROFILE_PASSWORD,
} from './types';

const initialState = {
  requesting: false,
  backendErrors: false,
  editPasswordMsg: false,
  signupErrors: false,
  forgetPasswordErrors: false,
  verificationRequesting: false,
  loginErrors: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN:
      return {
        ...state,
        requesting: true,
        loginErrors: false,
      };

    case SIGNIN_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        requesting: false,
        loginErrors: action.data,
      };

    case SIGNUP:
      return {
        ...state,
        requesting: true,
        signupErrors: false,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case SIGNUP_FAILED:
      return {
        ...state,
        requesting: false,
        // backendErrors: 'Registration Failed',
        signupErrors: action.data,
      };

    case RESET_MESSAGE:
      return {
        ...state,
        backendErrors: '',
      };

    case SIGNUP_RESET_MESSAGE:
      return {
        ...state,
        backendErrors: '',
      };

    case UPDATE_PASSWORD:
    case PROFILE_PASSWORD:
      return {
        ...state,
        requesting: true,
        editPasswordMsg: false,
      };

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        requesting: false,
        editPasswordMsg: action.data,
      };

    case UPDATE_PASSWORD_RESET:
      return {
        ...state,
        editPasswordMsg: false,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        requesting: true,
        forgetPasswordErrors: false,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        forgetPasswordErrors: false,
      };

    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        requesting: false,
        forgetPasswordErrors: action.data,
      };

    case VERIFICATION:
      return {
        ...state,
        backendErrors: false,
        verificationRequesting: true,
      };

    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationRequesting: false,
      };

    case VERIFICATION_FAILED:
      return {
        ...state,
        verificationRequesting: false,
        backendErrors: action.data,
      };

    default:
      return state;
  }
};
