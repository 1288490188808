import { all, call, put, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import { push } from 'connected-react-router';
// config
import { BASE_URL } from '../../../config/app';
// utils
import XHR from '../../../utils/XHR';

//Navigation

// types
import { GET_TERMS_CONDITIONS, GET_PRIVACY_POLICY } from './types';

//action
import {
  getPrivacyPolicyAndTermsConditionsFailed,
  getTermsConditionsSuccess,
  getPrivacyPolicySuccess,
} from './actions';

//Privacy Policy
async function getPrivacyPolicyApi() {
  const URL = `${BASE_URL}/privacy_policy/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

//Terms Conditions

async function termsConditionsAPI() {
  const URL = `${BASE_URL}/term_and_condition/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getPrivacyPolicy() {
  try {
    const response = yield call(getPrivacyPolicyApi);

    yield put(getPrivacyPolicySuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getPrivacyPolicyAndTermsConditionsFailed(response.data.message));
  }
}

function* getTermsConditions() {
  try {
    const response = yield call(termsConditionsAPI);

    yield put(getTermsConditionsSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getPrivacyPolicyAndTermsConditionsFailed(response.data.message));
  }
}

export default all([
  takeLatest(GET_TERMS_CONDITIONS, getTermsConditions),
  takeLatest(GET_PRIVACY_POLICY, getPrivacyPolicy),
]);
