import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';

import { push } from 'connected-react-router';
import toast, { Toaster } from 'react-hot-toast';

// utils
import XHR from '../../../utils/XHR';

// types
import { GET_EVENTS, DELETE_EVENTS, SEARCH_EVENTS, FILTER_EVENTS, GET_EVENT_BY_ID } from './types';

//action
import { getEventsSucces, getEventsFailed, reset, getEvents as getAllEvents } from './actions';
async function getEventsData() {
  const URL = `${BASE_URL}/event/`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function getEventsAPI(data) {
  const URL = `${BASE_URL}/event/?page=${data}`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function deleteEventsAPI(id) {
  const URL = `${BASE_URL}/event/${id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

async function filterEventsAPI(data) {
  const URL = `${BASE_URL}/event/?business_type=${data.category}&city=${data.city}&state=${data.state}&zip=${data.zipcode}&search=${data.searchText}`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* filterEvents({ data }) {
  try {
    const response = yield call(filterEventsAPI, data);
    yield put(getEventsSucces(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getEventsFailed(response.data));
  }
}

function* deleteEvents({ id, setModal }) {
  try {
    const response = yield call(deleteEventsAPI, id);
    setModal(false);
    yield put(getAllEvents(1));
  } catch (e) {
    const { response } = e;
    yield put(reset());
  }
}

function* getEvents({ data }) {
  try {
    const response = yield call(data ? getEventsAPI : getEventsData, data);
    yield put(getEventsSucces(response.data));
  } catch (e) {
    const { response } = e;
    // yield put(getEventsFailed(response.data))
    yield put(reset());
  }
}

async function getEventByIdAPI(id) {
  const URL = `${BASE_URL}/event/${id}/`;

  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getEventById({ id }) {
  try {
    const response = yield call(getEventByIdAPI, id);
    if (response.data) {
      sessionStorage.setItem('eventDetail', JSON.stringify(response.data));
      yield put(
        push({
          pathname: '/admin/editEvent',
        })
      );
    }
  } catch (e) {
    const { response } = e;
    // yield put(getEventsFailed(response.data))
    yield put(reset());
  }
}

export default all([
  takeLatest(GET_EVENTS, getEvents),
  takeLatest(DELETE_EVENTS, deleteEvents),
  takeLatest(FILTER_EVENTS, filterEvents),
  takeLatest(GET_EVENT_BY_ID, getEventById),
]);
