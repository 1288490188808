import {
  GET_STORIES,
  GET_STORIES_FAILED,
  GET_STORIES_SUCCESS,
  UPDATE_STORY,
  DELETE_STORY,
  FILTER_STORIES,
  RESET,
} from './types';

const initialState = {
  requesting: false,
  stories: false,
  deleteStoryRequesting: false,
  backendError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORIES:
    case FILTER_STORIES:
      return {
        ...state,
        requesting: true,
      };

    case UPDATE_STORY:
      return {
        ...state,
        requesting: true,
        backendError: false,
      };

    case DELETE_STORY:
      return {
        ...state,
        deleteStoryRequesting: true,
        backendError: false,
      };

    case GET_STORIES_FAILED:
      return {
        ...state,
        requesting: false,
        backendError: action.data,
      };

    case GET_STORIES_SUCCESS:
      return {
        ...state,
        requesting: false,
        stories: action.data,
      };

    case RESET:
      return {
        ...state,
        backendError: '',
        requesting: false,
        deleteStoryRequesting: false,
      };

    default:
      return state;
  }
};
