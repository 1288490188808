import { GET_SERVICES, GET_SERVICES_SUCCESS } from './types';

const initialState = {
  services: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.data,
      };

    default:
      return state;
  }
};
