export const ADD_STORY_REQUEST = 'Portfolio/AddStory/ADD_STORY_REQUEST';
export const ADD_STORY_SUCCESS = 'Portfolio/AddStory/ADD_STORY_SUCCESS';
export const ADD_STORY_FAILURE = 'Portfolio/AddStory/ADD_STORY_FAILURE';
export const GET_STORY_BY_ID_REQUEST = 'Portfolio/AddStory/GET_STORY_BY_ID_REQUEST';
export const GET_STORY_TYPE = 'Portfolio/AddStory/GET_STORY_TYPE';
export const GET_STORY_TYPE_SUCCESS = 'Portfolio/AddStory/GET_STORY_TYPE_SUCCESS';


export const UPDATE_STORY_REQUEST = 'Portfolio/AddStory/UPDATE_STORY_REQUEST';
export const ADD_STORY_GALLARY = 'Portfolio/AddStory/ADD_STORY_GALLARY';
export const DELETE_STORY_GALLARY = 'Portfolio/AddStory/DELETE_STORY_GALLARY';







