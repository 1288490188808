import {
  GET_EVENTS,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  DELETE_EVENTS,
  FILTER_EVENTS,
  SEARCH_BY_TYPE_EVENTS,
  GET_EVENT_BY_ID,
  RESET,
} from './types';

export const getEvents = (data) => ({
  type: GET_EVENTS,
  data,
});

export const getEventById = (id) => ({
  type: GET_EVENT_BY_ID,
  id,
});

export const getEventsFailed = (data) => ({
  type: GET_EVENTS_FAILED,
  data,
});

export const getEventsSucces = (data) => ({
  type: GET_EVENTS_SUCCESS,
  data,
});

export const deleteEvents = (id, setModal) => ({
  type: DELETE_EVENTS,
  id,
  setModal,
});

export const searchByTypeEvents = (data) => ({
  type: SEARCH_BY_TYPE_EVENTS,
  data,
});

export const filterEvents = (data) => ({
  type: FILTER_EVENTS,
  data,
});

export const reset = () => ({
  type: RESET,
});
