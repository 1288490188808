export const ADD_CARD_PACKAGE = 'Portfolio/CardPackage/ADD_CARD_PACKAGE';
export const ADD_CARD_PACKAGE_FAILED = 'Portfolio/CardPackage/ADD_CARD_PACKAGE_FAILED';

export const GET_CARD_LIST = 'Portfolio/CardPackage/GET_CARD_LIST';
export const GET_CARD_LIST_SUCCESS = 'Portfolio/CardPackage/GET_CARD_LIST_SUCCESS';
export const GET_CARD_LIST_FAILED = 'Portfolio/CardPackage/GET_CARD_LIST_FAILED';

export const DELETE_CARD = 'Portfolio/CardPackage/DELETE_CARD';

export const CARD_SELECTION = 'Portfolio/CardPackage/CARD_SELECTION';
export const CARD_SELECTION_RESET = 'Portfolio/CardPackage/CARD_SELECTION_RESET';
