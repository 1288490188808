import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';

import { push } from 'connected-react-router';

// utils
import XHR from '../../../utils/XHR';

// types
import { GET_STORIES, UPDATE_STORY, DELETE_STORY, SEARCH_STORIES, FILTER_STORIES } from './types';

//action
import { getStoriesSucces, getStories as getAllStories, reset, getStoriesFailed } from './actions';

async function getStoriesAPI(data) {
  const URL = `${BASE_URL}/story/?page=${data}`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function deleteStoryAPI(id) {
  const URL = `${BASE_URL}/story/${id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

async function updateStoryAPI(data) {
  const URL = `${BASE_URL}/story/${data.id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data,
  };
  return XHR(URL, options);
}

async function filterStoriesAPI(data) {
  const URL = `${BASE_URL}/story/?status=${data.status}&search=${data.searchText}`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* filterStories({ data }) {
  try {
    const response = yield call(filterStoriesAPI, data);
    yield put(getStoriesSucces(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getStoriesFailed(response.data));
  } finally {
    yield put(reset());
  }
}

function* updateStory({ data }) {
  try {
    yield call(updateStoryAPI, data);
    yield put(
      push({
        pathname: '/business',
      })
    );
    sessionStorage.removeItem('editBusinesses');
  } catch (e) {
    const { response } = e;
    // yield put(getStoriesFailed(response.data));
  }
}

function* deleteStory({ id, setModal }) {
  try {
    yield call(deleteStoryAPI, id);
    setModal(false);
    yield put(getAllStories(1));
  } catch (e) {
    const { response } = e;
    yield put(reset());
  }
}

function* getStories({ data }) {
  try {
    const response = yield call(getStoriesAPI, data);

    yield put(getStoriesSucces(response.data));
  } catch (e) {
    const { response } = e;
    yield put(reset());
  } finally {
    yield put(reset());
  }
}

export default all([
  takeLatest(GET_STORIES, getStories),
  takeLatest(DELETE_STORY, deleteStory),
  takeLatest(UPDATE_STORY, updateStory),
  takeLatest(FILTER_STORIES, filterStories),
]);
