export const ADD_BUSINESS = 'Portfolio/Business/ADD_BUSINESS';
export const ADD_BUSINESS_FAILED = 'Portfolio.Business.ADD_BUSINESS_FAILED';

export const UPDATE_BUSINESS = 'Portfolio/Business/UPDATE_BUSINESS';

export const DELETE_BUSINESS = 'Portfolio/Business/DELETE_BUSINESS';

export const GET_BUSINESS = 'Portfolio/Business/GET_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'Portfolio/Business/GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILED = 'Portfolio/Business/GET_BUSINESS_FAILED';

export const GET_BUSINESS_BY_ID = 'PortfolioBusiness/GET_BUSINESS_BY_ID';
export const GET_BUSINESS_BY_ID_SUCCESS = 'Portfolio/Business/GET_BUSINESS_BY_ID_SUCCESS';

export const EDIT_BUSINESS = 'Portfolio/Business/EDIT_BUSINESS';

export const SEARCH_BUSINESS = 'Portfolio/Business/SEARCH_BUSINESS';
export const FILTER_BUSINESS = 'Portfolio/Business/FILTER_BUSINESS';

export const RESET_MESSAGE = 'Portfolio/Business/RESET_MESSAGE';
export const GET_CUISINES = 'Portfolio/Business/GET_CUISINES';
export const GET_CUISINES_SUCCESS = 'Portfolio/Business/GET_CUISINES_SUCCESS';

export const UPLOAD_BULK_CSV = 'Portfolio/Business/UPLOAD_BULK_CSV';
export const UPLOAD_BULK_CSV_SUCCESS = 'Portfolio/Business/UPLOAD_BULK_CSV_SUCCESS';
export const UPLOAD_BULK_CSV_FAILURE = 'Portfolio/Business/UPLOAD_BULK_CSV_FAILURE';

export const DOWNLOAD_CSV_SAMPLE = 'Portfolio/Business/DOWNLOAD_CSV_SAMPLE';
export const DOWNLOAD_CSV_SAMPLE_SUCCESS = 'Portfolio/Business/DOWNLOAD_CSV_SAMPLE_SUCCESS';
export const DELETE_SERVICES = 'Portfolio/Business/DELETE_SERVICES';
export const DELETE_SERVICES_SUCCESS = 'Portfolio/Business/DELETE_SERVICES_SUCCESS';

export const UPDATE_PREFERED = 'Portfolio/Business/UPDATE_PREFERED';