export const GET_EVENTS = 'Portfolio/Events/GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'Portfolio/Events/GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'Portfolio/Events/GET_EVENTS_FAILED';

export const DELETE_EVENTS = 'Portfolio/Events/DELETE_EVENTS';

export const SEARCH_EVENTS = 'Portfolio/Events/SEARCH_EVENTS';
export const FILTER_EVENTS = 'Portfolio/Events/FILTER_EVENTS';
export const SEARCH_BY_TYPE_EVENTS = 'Portfolio/Events/SEARCH_BY_TYPE_EVENTS';
export const GET_EVENT_BY_ID = 'Portfolio/Events/GET_EVENT_BY_ID';

export const RESET = 'Portfolio/Events/RESET';

