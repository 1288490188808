import {
  ADD_CARD_PACKAGE,
  ADD_CARD_PACKAGE_FAILED,
  GET_CARD_LIST,
  GET_CARD_LIST_FAILED,
  GET_CARD_LIST_SUCCESS,
  DELETE_CARD,
  CARD_SELECTION,
  CARD_SELECTION_RESET,
} from './types';

export const addCardPackage = (data, createSubscription, modalState) => ({
  type: ADD_CARD_PACKAGE,
  data,
  createSubscription,
  modalState,
});

export const addCardPackageFailed = (data) => ({
  type: ADD_CARD_PACKAGE_FAILED,
  data,
});

export const getCardList = () => ({
  type: GET_CARD_LIST,
});

export const getCardListSuccess = (data) => ({
  type: GET_CARD_LIST_SUCCESS,
  data,
});
export const getCardListFailed = () => ({
  type: GET_CARD_LIST_FAILED,
});

export const deleteCard = (data) => ({
  type: DELETE_CARD,
  data,
});

export const cardSelection = (data, state) => ({
  type: CARD_SELECTION,
  data,
  state,
});

export const cardSelectionReset = () => ({
  type: CARD_SELECTION_RESET,
});
