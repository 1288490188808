import {
  GET_PACKAGE,
  GET_PACKAGE_FAILED,
  GET_PACKAGE_SUCCESS,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_REQUEST_SUCCESS
} from './types';

const initialState = {
  requesting: false,
  userPackage: false,
  backendErrors: false,

};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_PACKAGE:
    case CREATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        requesting: true,
      };
      

    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        userPackage: action.data,
      };

      case CREATE_SUBSCRIPTION_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case GET_PACKAGE_FAILED:
      return {
        ...state,
        requesting: false,
        userPackage: false,
        backendErrors: action.error,
      };

    default:
      return state;
  }
};
