export const GET_STORIES = 'Portfolio/Stories/GET_STORIES';
export const GET_STORIES_FAILED = 'Portfolio/Stories/GET_STORIES_FAILED';

export const GET_STORIES_SUCCESS = 'Portfolio/Stories/GET_STORIES_SUCCESS';

export const UPDATE_STORY = 'Portfolio/Stories/UPDATE_STORY';
export const DELETE_STORY = 'Portfolio/Stories/DELETE_STORY';
export const FILTER_STORIES = 'Portfolio/Stories/FILTER_STORIES';


export const RESET = 'Portfolio/Stories/RESET';
