import { all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

//Saga
import login from '../Containers/Login/redux/sagas';
import business from '../Containers/Business/redux/saga';
import services from '../Containers/AddBussines/redux/saga';
import accountSetting from '../Containers/AccountSetting/redux/saga'
import selectPackage from 'Containers/SelectPlan/redux/saga';
import privacyPolicyAndTermsConditions from 'Containers/PrivacyPolicy/redux/saga';
import cardPackage from '../Containers/BasicPackage/redux/saga';
import events from '../Containers/Events/redux/saga';
import stories from '../Containers/Stories/redux/saga';

import addEvent from '../Containers/AddEvent/redux/saga';
import addStory from '../Containers/AddStory/redux/saga';



export function* mainSaga() {
  yield all([login, business, services, accountSetting, selectPackage, privacyPolicyAndTermsConditions, cardPackage, addEvent, events, stories, addStory]);
}
