import {
  // GET_ACCESS_TOKEN,
  GET_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_FAILED,
  CHANGE_SUBSCRIPTION_SUCCESS,
} from './types';

const initialState = {
  requesting: false,
  // accessToken: false,
  userDetails: false,
  backendErrors: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_DETAILS:
    case UPDATE_ACCOUNT_DETAILS:
    case CHANGE_SUBSCRIPTION:
      return {
        ...state,
        requesting: true,
        backendErrors: false,
      };

    case GET_ACCOUNT_FAILED:
      return {
        ...state,
        requesting: false,
        userDetails: false,
        backendErrors: action.error,
      };

    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        requesting: false,
        userDetails: action.data,
      };

    case CHANGE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case CHANGE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        requesting: false,
        backendErrors: action.data,
      };

    default:
      return state;
  }
};
