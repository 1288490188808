
export const ADD_EVENT_REQUEST = 'Portfolio/AddEvent/ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'Portfolio/AddEvent/ADD_EVENT_SUCCESS';

export const ADD_EVENT_FAILURE = 'Portfolio/AddEvent/ADD_EVENT_FAILURE';
export const ADD_EVENT_GALLARY = 'Portfolio/AddEvent/ADD_EVENT_GALLARY';
export const DELETE_EVENT_GALLARY = 'Portfolio/AddEvent/DELETE_EVENT_GALLARY';
export const UPDATE_EVENTS = 'Portfolio/AddEvent/UPDATE_EVENTS';
export const GET_EVENT_KINDS = 'Portfolio/AddEvent/GET_EVENT_KINDS';
export const GET_EVENT_KINDS_SUCCESS = 'Portfolio/AddEvent/GET_EVENT_KINDS_SUCCESS';






