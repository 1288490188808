/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardTitle,
} from 'reactstrap';
import { push } from 'connected-react-router';

class AdminNavbar extends React.Component {
  state = {
    modalVisible: false,
  };

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  };
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-green',
      email: '',
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateColor);
    const user = localStorage.getItem('user');
    const data = JSON.parse(user);
    this.setState({ email: data.email });
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'navbar-green',
      });
    } else {
      this.setState({
        color: 'navbar-green',
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState['color'] = 'navbar-green';
    } else {
      newState['color'] = 'navbar-green';
    }
    this.setState(newState);
  };
  
  render() {
    const { modalVisible } = this.state;
    return (
      <>
        <Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand="lg">
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classnames('navbar-toggle', {
                  toggled: this.state.sidebarOpen,
                })}
              >
                <button className="navbar-toggler" type="button" onClick={this.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/* <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <span className="d-none d-md-block">
                  Paper Dashboard PRO React
                </span>
                <span className="d-block d-md-none">PD PRO React</span>
              </NavbarBrand> */}
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse className="justify-content-end" navbar isOpen={this.state.collapseOpen}>
              {/* <Form>
                <InputGroup className="no-border">
                  <Input defaultValue="" placeholder="Search..." type="text" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form> */}
              <Nav navbar>
                <NavItem className="EmailNavItem">
                  <p style={{ marginTop: 14, color: '#fff', fontWeight: 501 }}>
                    {this.state.email}
                  </p>
                </NavItem>
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    // caret
                    color="default"
                    // data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-bell-55 bellIconWeight1" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  {/* <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                      Action
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                      Another action
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                      Something else here
                    </DropdownItem>
                  </DropdownMenu> */}
                </UncontrolledDropdown>
                <NavItem>
                  <Button
                    className="btn-round1 "
                    color="danger"
                    onClick={() => {
                      this.setModalVisible(true);
                    }}
                  >
                    Logout
                  </Button>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className="btn-rotate"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <i className="nc-icon nc-settings-gear-65" />
                    <p>
                      <span className="d-lg-none d-md-block">Account</span>
                    </p>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal isOpen={modalVisible} style={{ borderRadius: '10px' }}>
          <CardTitle
            tag="h5"
            style={{
              backgroundColor: '#e86233',
              borderRadius: ' 10px 10px 0px 0px',
              color: 'white',
              height: 55,
            }}
            className=" text-center m-0 pt-2 pb-2"
          >
            <b> Logout</b>
          </CardTitle>

          <ModalBody style={{ padding: '18px 26px', height: 100 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <b style={{ fontSize: 20 }}>Are you sure you want to Logout?</b>
            </div>
          </ModalBody>
          <ModalFooter style={{ border: 'none' }} className="justify-content-center mb-3 ">
            <Button
              style={{
                borderRadius: '10px',
                backgroundColor: '#00cf78',
                marginRight: 10,
              }}
              className="btncvc"
              onClick={() => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userDetails');
                window.location.reload(false);
                this.setModalVisible(!modalVisible);
              }}
            >
              Yes
            </Button>
            <Button
              style={{
                borderRadius: '10px',
                backgroundColor: '#e86233',
              }}
              className="btncvc"
              onClick={() => {
                this.setModalVisible(false);
                sessionStorage.setItem('logoutState', false);
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default AdminNavbar;
