/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Nav,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  CardTitle,
} from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

import logo2 from '../Assets1/Group1.png';
import SettingArrowIcon from '../../../src/assets/img/settingArrowIcon.png';
import '../Sidebar/style.css';
var ps;

class Sidebar extends React.Component {
  state = {
    modalVisible: false,
    settingDrowpDown: true,
  };
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  };

  logout = (name) => {
    name === 'Log Out' && this.setState({ modalVisible: true });
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !this.state[prop.state];
        return (
          <li className={this.getCollapseInitialState(prop.views) ? '' : ''} key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);

                this.setState({ settingDrowpDown: !this.state.settingDrowpDown })
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                    <p style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                      <img src={prop.icon} style={{ marginRight: 20 }} />
                      {prop.name}
                    </p>

                    <img className={!this.state.settingDrowpDown ? "" : "Settingarrow"} style={{ height: 8, width: 15 }} src={SettingArrowIcon} />

                    {/* <b className="caret" /> */}
                  </div>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    {/* <b className="caret" /> */}
                    <img style={{ height: "100px" }} src={SettingArrowIcon} />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
              <ul className="nav">
                <div className='logout-link' style={{ display: 'flex', marginTop: "12px" }}>
                  <p
                    className='logout-link'
                    style={{
                      fontSize: 40,
                      // opacity: 1,
                      marginRight: 20,
                      marginLeft: 32,
                    }}
                  >
                    •
                  </p>
                  <p
                    style={{ fontSize: 12, marginTop: 2, fontWeight: ' 500', cursor: 'pointer' }}
                    className="logout-link"
                    onClick={() =>
                      this.logout("Log Out")
                    }
                  >
                    LOG OUT
                  </p>
                </div>
              </ul>
            </Collapse>
          </li>
        );
      }

      // const [modalVisible, setModalVisible] = useState(false);

      return (
        <>
          {!prop.isShow && (
            <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
              <NavLink to={prop.layout + prop.path} activeClassName="">
                <div style={{ display: 'flex' }}>
                  {prop.icon !== undefined ? (
                    <>
                      <img src={prop.icon} style={{ marginRight: 20 }} alt="" />
                      <p>{prop.name}</p>
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          fontSize: 40,
                          color: '#fffff',
                          opacity: 1,
                          marginRight: 20,
                          marginLeft: 10,
                        }}
                      >
                        {prop.mini}
                      </p>
                      <p
                        style={{ fontSize: 12, color: '#fff', marginTop: 2, fontWeight: ' 500' }}
                        className="sidebar-normal"
                      // onClick={() =>
                      //   this.logout(prop.name)
                      // }
                      >
                        {prop.name}
                      </p>
                    </>
                  )}
                </div>
              </NavLink>
            </li>
          )}
        </>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    const { modalVisible } = this.state;

    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        style={{ backgroundColor: 'rgb(17, 222, 138)' }}
      // data-active-color={this.props.activeColor}
      >
        <div
          className="logo"
          style={{
            backgroundColor: 'rgb(17, 222, 138)',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to={"/admin/business"}>
            <img src={logo2} height={95} width={95} className="sideImage1" />
          </Link>
          <div className="sideHead1" style={{ backgroundColor: 'green' }}>
            {/* <p className='sideHead2'>Welcome Home - Anywhere!</p> */}
          </div>
          {/* <p style={{ fontWeight: 'bold', marginLeft: 60, fontSize: 27 }}>Portfolio</p> */}
        </div>

        {/* <a
            href='https://www.creative-tim.com'
            className='simple-text logo-mini'
          >
            <div className='logo-img'>
              <img src={logo} alt='react-logo' />
            </div>
          </a>
          <a
            href='https://www.creative-tim.com'
            className='simple-text logo-normal'
          >
            Portfolio
          </a> */}

        <div className="sidebar-wrapper11" ref="sidebar">
          {/* <div className='user'> */}
          {/* <div className='photo'>
              <img src={avatar} alt='Avatar' />
            </div> */}
          {/* <div className='info'>
              <a
                href='#pablo'
                data-toggle='collapse'
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({openAvatar: !this.state.openAvatar})
                }
              >
                <span>
                  Chet Faker
                  <b className='caret' />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className='nav'>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>MP</span>
                      <span className='sidebar-normal'>My Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>EP</span>
                      <span className='sidebar-normal'>Edit Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>S</span>
                      <span className='sidebar-normal'>Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div> */}
          {/* </div> */}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>

        <Modal isOpen={this.state.modalVisible} style={{ borderRadius: '10px' }}>
          <CardTitle
            tag="h5"
            style={{
              backgroundColor: '#e86233',
              borderRadius: ' 10px 10px 0px 0px',
              color: 'white',
              height: 55,
            }}
            className=" text-center m-0 pt-2 pb-2"
          >
            <b> Logout</b>
          </CardTitle>

          <ModalBody style={{ padding: '18px 26px', height: 100 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <b style={{ fontSize: 20 }}>Are you sure you want to Logout?</b>
            </div>
          </ModalBody>
          <ModalFooter style={{ border: 'none' }} className="justify-content-center mb-3 ">
            <Button
              style={{
                borderRadius: '10px',
                backgroundColor: '#00cf78',
                marginRight: 10,
              }}
              className="btncvc"
              onClick={() => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userDetails');
                window.location.reload(false);
                this.setModalVisible(false);
              }}
            >
              Yes
            </Button>
            <Button
              style={{
                borderRadius: '10px',
                backgroundColor: '#e86233',
              }}
              className="btncvc"
              onClick={() => {
                this.setModalVisible(false);
                // sessionStorage.setItem('logoutState', false);
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Sidebar;
