import {
  ADD_STORY_REQUEST,
  ADD_STORY_SUCCESS,
  ADD_STORY_FAILURE,
  UPDATE_STORY_REQUEST,
  GET_STORY_TYPE_SUCCESS,
} from './types';

const initialState = {
  requesting: false,
  data: false,
  error: false,
  storyTypes: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_STORY_REQUEST:
    case UPDATE_STORY_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case ADD_STORY_SUCCESS:
      return {
        ...state,
        data: action.data,
        requesting: false,
        error: false,
      };
    case GET_STORY_TYPE_SUCCESS:
      return {
        ...state,
        storyTypes: action.data,
      };
    case ADD_STORY_FAILURE:
      return {
        ...state,
        error: action.error,
        requesting: false,
      };

    default:
      return state;
  }
};
