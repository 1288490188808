import React from "react"
import { Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"

const RouteGuard = ({
  isProtected = false,
  component: Component,
  ...rest
}) => {

  const accessToken = localStorage.getItem('accessToken')

  return (
    <>
      <Route
        {...rest}
        render={props => {
          if (isProtected) {
            return accessToken ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: "/auth/login" }} />
            )
          } else {
            return accessToken ? (
              <Redirect
                to={{
                  pathname: "/"
                }}
              />
            ) : (
              <Component {...props} />
            )
          }
        }}
      />
    </>
  )
}

const mapStateToProps = state => ({
  // accessToken: state.login.accessToken
  // userDetails: state.signIn.userDetails,
  // timerRef: state.flightSearch.timerRef
})

// const mapStateToProps = createStructuredSelector({
//     user: makeSelectUser(),
// });


export default connect(mapStateToProps)(RouteGuard)
