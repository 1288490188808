import { all, call, put, delay, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import { push } from 'connected-react-router';
// config
import { BASE_URL } from '../../../config/app';
// utils
import XHR from '../../../utils/XHR';

import toast, { Toaster } from 'react-hot-toast';

//Navigation

// types
import { ADD_CARD_PACKAGE, CARD_SELECTION, DELETE_CARD, GET_CARD_LIST } from './types';

//action
import {
  addCardPackageFailed,
  getCardListSuccess,
  getCardList as getCards,
  getCardListFailed,
  cardSelectionReset,
  getCardList as getCardListView,
} from './actions';
import { createSubscriptionRequest } from '../../SelectPlan/redux/actions';

async function addCardPackageApi(data) {
  const URL = `${BASE_URL}/stripe/add_card/`;
  // const accessToken = await localStorage.getItem('token');

  const accessToken = await localStorage.getItem('accessToken');
  const token = await localStorage.getItem('token');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken ? accessToken : token}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function deleteCardAPI(data) {
  const URL = `${BASE_URL}/stripe/delete_card/`;
  const accessToken = await localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
    data,
  };
  return XHR(URL, options);
}

async function cardSelectionAPI(data) {
  const URL = `${BASE_URL}/stripe/card_selection/`;
  const accessToken = await localStorage.getItem('token');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function getCardListAPI() {
  const URL = `${BASE_URL}/stripe/list_card/`;

  // if (await localStorage.getItem('accessToken')) {
  //   const accessToken = await localStorage.getItem('accessToken');
  // } else {
  //   const accessToken = await localStorage.getItem('token');
  // }

  // const accessToken = await localStorage.getItem('accessToken');
  const accessToken = await localStorage.getItem('accessToken');
  const token = await localStorage.getItem('token');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken ? accessToken : token}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getCardList() {
  try {
    const response = yield call(getCardListAPI);
    yield put(getCardListSuccess(response.data));
  } catch (e) {
    const { response } = e;
  }
}

function* addCardPackage({ data, createSubscription, modalState }) {
  try {
    const response = yield call(addCardPackageApi, data);
    if (createSubscription) {
      yield delay(4000);
      const payload = sessionStorage.getItem('packageId');
      yield put(createSubscriptionRequest({ package_id: payload }));
      yield put(addCardPackageFailed(false));
    } else {
      yield put(
        addCardPackageFailed('Card added Successfully! You can now proceed to change subscription')
      );

      yield put(getCardListView());
      modalState(false);
    }
  } catch (e) {
    const { response } = e;
    toast.error(`${response?.data ? response.data : 'Failed'}`);
    yield put(addCardPackageFailed(response.data.error));
  }
}

function* deleteCard({ data }) {
  try {
    const response = yield call(deleteCardAPI, data);
    toast.success(response?.data ? response.data : 'Card Deleted');
    yield put(getCards());
  } catch (e) {
    const { response } = e;
    toast.error(response?.data ? response.data : 'Something went wrong.');
  }
}

function* cardSelection({ data, state }) {
  try {
    const response = yield call(cardSelectionAPI, data);
    yield delay(4000);
    const payload = sessionStorage.getItem('packageId');
    yield put(createSubscriptionRequest({ package_id: payload }));
  } catch (e) {
    const { response } = e;
    yield put(cardSelectionReset());
    state('Card selection failed!');
  }
}
export default all([
  takeLatest(ADD_CARD_PACKAGE, addCardPackage),
  takeLatest(GET_CARD_LIST, getCardList),
  takeLatest(DELETE_CARD, deleteCard),
  takeLatest(CARD_SELECTION, cardSelection),
]);
