import {
  GET_EVENTS,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  DELETE_EVENTS,
  RESET,
  FILTER_EVENTS,
} from './types';

const initialState = {
  requesting: false,
  events: false,
  deleteEventRequest: false,
  backendError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS:
    case FILTER_EVENTS:
      return {
        ...state,
        requesting: true,
      };

    case DELETE_EVENTS:
      return {
        ...state,
        deleteEventRequest: true,
      };

    case GET_EVENTS_FAILED:
      return {
        ...state,
        requesting: false,
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        requesting: false,
        events: action.data,
        deleteEventRequest: false,
      };

    case RESET:
      return {
        ...state,
        backendError: '',
        requesting: false,
        deleteEventRequest: false,
      };

    default:
      return state;
  }
};
