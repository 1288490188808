import { all, call, put, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import { push } from 'connected-react-router';
import toast from 'react-hot-toast';
// config
import { BASE_URL, DOMAIN } from '../../../config/app';

// utils
import XHR from '../../../utils/XHR';

//Navigation

// types
import {
  SIGNUP,
  SIGNIN,
  UPDATE_PASSWORD,
  FORGOT_PASSWORD,
  VERIFICATION,
  PROFILE_PASSWORD,
} from './types';

//action
import {
  loginFailed,
  signinSuccess,
  signupSuccess,
  signupFailed,
  updatePasswordFailed,
  updatePasswordSuccess,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  verificationSuccess,
  verificationFailed,
} from './actions';

async function signUpAPI(data) {
  const URL = `${BASE_URL}/signup/`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function signInAPI(data) {
  const URL = `${BASE_URL}/login/`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function forgotPasswordAPI(data) {
  const URL = `${BASE_URL}/password_reset/`;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function verificationAPI(data) {
  const URL = `${BASE_URL}/validate_reset_token/`;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function updatePasswordAPI(data) {
  const URL = `${DOMAIN}/rest-auth/password/change/`;
  const accessToken = await JSON.parse(sessionStorage.getItem('passwordToken'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function profilePasswordAPI(data) {
  const URL = `${DOMAIN}/api/v1/password/change/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}
function* forgetPassword({ data }) {
  try {
    const response = yield call(forgotPasswordAPI, data);
    // localStorage.setItem('userData ', JSON.stringify({ email: data.email }));
    //
    yield put(forgetPasswordSuccess());
    yield put(
      push({
        pathname: '/auth/Verification',
      })
    );
  } catch (e) {
    const { response } = e;

    yield put(forgetPasswordFailed(response.data));
  }
}

function* verification({ data }) {
  try {
    const response = yield call(verificationAPI, data);
    localStorage.removeItem('userEmail');
    toast.success('verification Successfully');
    yield put(
      push({
        pathname: '/auth/UpdatePassword',
      })
    );
    const token = response.data.password_reset_token;
    sessionStorage.setItem('passwordToken', JSON.stringify(token));
    yield put(verificationSuccess());
  } catch (e) {
    const { response } = e;

    yield put(verificationFailed('Invalid Token'));
  }
}

function* updatePassword({ data }) {
  try {
    const response = yield call(updatePasswordAPI, data);
    toast.success('Password Updated Successfully');

    yield put(updatePasswordSuccess(response.data.detail));
    yield put(
      push({
        pathname: '/auth/login',
      })
    );
    sessionStorage.removeItem('passwordToken');
  } catch (e) {
    const { response } = e;
    yield put(updatePasswordFailed(response.data.detail));
  }
}

function* profilePassword({ data, resetValue }) {
  try {
    const response = yield call(profilePasswordAPI, data);
    toast.success('Password Updated Successfully');
    resetValue();
    yield put(updatePasswordSuccess(response.data.detail));
  } catch (e) {
    const { response } = e;
    yield put(updatePasswordFailed(response.data.detail));
    toast.error(
      `${response?.data?.new_password2[0] ? response?.data?.new_password2[0] : 'Failed'}`
    );
  }
}

function* signUp({ data }) {
  try {
    const response = yield call(signUpAPI, data);
    yield put(signupSuccess());
    toast.success('SignUp Successfully');
    yield put(
      push({
        pathname: '/auth/login',
      })
    );
  } catch (e) {
    const { response } = e;
    yield put(signupFailed(response.data));
  }
}

function* signIn({ data }) {
  try {
    const response = yield call(signInAPI, data);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    if (response.data.user.subscription) {
      localStorage.setItem('accessToken', response.data.token);
      yield put(
        push({
          pathname: '/admin/business',
        })
      );
    } else {
      localStorage.setItem('token', response.data.token);
      yield put(
        push({
          pathname: '/auth/SelectPlan',
        })
      );
    }
    yield put(signinSuccess());
    toast.success('Login Successfully');
  } catch (e) {
    const { response } = e;

    yield put(loginFailed(response.data));
  }
}

export default all([
  takeLatest(SIGNUP, signUp),
  takeLatest(SIGNIN, signIn),
  takeLatest(FORGOT_PASSWORD, forgetPassword),
  takeLatest(UPDATE_PASSWORD, updatePassword),
  takeLatest(VERIFICATION, verification),
  takeLatest(PROFILE_PASSWORD, profilePassword),
]);
