import {
  GET_TERMS_CONDITIONS,
  GET_PRIVACY_POLICY,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_AND_TERMS_CONDITIONS_FAILED
} from './types';

export const getPrivacyPolicy = () => ({
  type: GET_PRIVACY_POLICY
});

export const getPrivacyPolicySuccess = (data) => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  data,
});

export const getTermsConditions = () => ({
  type: GET_TERMS_CONDITIONS
});

export const getTermsConditionsSuccess = (data) => ({
  type: GET_TERMS_CONDITIONS_SUCCESS,
  data,
});


export const getPrivacyPolicyAndTermsConditionsFailed = (error) => ({
  type: GET_PRIVACY_POLICY_AND_TERMS_CONDITIONS_FAILED,
  error,
});



