import {
  GET_BUSINESS,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_BY_ID_SUCCESS,
  ADD_BUSINESS,
  EDIT_BUSINESS,
  ADD_BUSINESS_FAILED,
  RESET_MESSAGE,
  GET_BUSINESS_BY_ID,
  GET_CUISINES_SUCCESS,
  UPLOAD_BULK_CSV,
  UPLOAD_BULK_CSV_SUCCESS,
  UPLOAD_BULK_CSV_FAILURE,
  SEARCH_BUSINESS,
  FILTER_BUSINESS,
  UPDATE_PREFERED
} from './types';

const initialState = {
  requesting: false,
  business: false,
  backendError: false,
  cuisines: false,
  editRequesting: false,
  uploadCSVSuccess: false,
  CSVRequesting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS:
    case SEARCH_BUSINESS:
    case UPDATE_PREFERED:
    case FILTER_BUSINESS:
      return {
        ...state,
        requesting: true,
      };
    case GET_BUSINESS_BY_ID:
      return {
        ...state,
        editRequesting: true,
      };

    case GET_CUISINES_SUCCESS:
      return {
        ...state,
        cuisines: action.data,
      };
    case EDIT_BUSINESS:
      return {
        ...state,
        requesting: true,
        backendError: false,
      };

    case ADD_BUSINESS:
      return {
        ...state,
        requesting: true,
        backendError: false,
      };

    case ADD_BUSINESS_FAILED:
      return {
        ...state,
        requesting: false,
        backendError: action.data,
      };

    case GET_BUSINESS_SUCCESS:
      return {
        ...state,
        requesting: false,
        business: action.data,
      };
    case GET_BUSINESS_FAILED:
      return {
        ...state,
        requesting: false,
        backendError: action.data,
      };

    case UPLOAD_BULK_CSV:
      return {
        ...state,
        CSVRequesting: true,
        backendError: action.data,
      };

    case UPLOAD_BULK_CSV_SUCCESS:
      return {
        ...state,
        CSVRequesting: false,
        uploadCSVSuccess: action.data,
      };
    case UPLOAD_BULK_CSV_FAILURE:
      return {
        ...state,
        CSVRequesting: false,
        backendError: action.data,
      };

    case RESET_MESSAGE:
      return {
        ...state,
        backendError: '',
      };
    case GET_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        editRequesting: false,
      };

    default:
      return state;
  }
};
