import { ADD_EVENT_REQUEST, ADD_EVENT_SUCCESS, ADD_EVENT_FAILURE, UPDATE_EVENTS , GET_EVENT_KINDS_SUCCESS} from './types';

const initialState = {
  requesting: false,
  addEventSuccess: false,
  backendError: false,
  eventKinds:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT_REQUEST:
      case UPDATE_EVENTS:
      return {
        ...state,
        requesting: true
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        addEventData: action.data,
        requesting: false,
        backendError:false
      };
      case GET_EVENT_KINDS_SUCCESS:
        return {
          ...state,
          eventKinds: action.data,
        };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        backendError: action.data,
        requesting: false,
      };

    default:
      return state;
  }
};
