export const SIGNIN = 'Portfolio/Login/SIGNIN';
export const SIGNIN_SUCCESS = 'Portfolio/Login/SIGNIN_SUCCESS';
export const LOGIN_FAILED = 'Portfolio/Login/LOGIN_FAILED';

export const SIGNUP = 'Portfolio/Signup/SIGNUP';
export const SIGNUP_SUCCESS = 'Portfolio/Signup/SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'Portfolio/Signup/SIGNUP_FAILED';

export const UPDATE_PASSWORD = 'Portfolio/Login/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'Portfolio/Login/UPDATE_PASSWORD_SUCCESS';
export const PROFILE_PASSWORD = 'Portfolio/Login/PROFILE_PASSWORD';
export const UPDATE_PASSWORD_FAILED = 'Portfolio/Login/UPDATE_PASSWORD_FAILED';
export const UPDATE_PASSWORD_RESET = 'Portfolio/Login/UPDATE_PASSWORD_RESET ';

export const FORGOT_PASSWORD = 'Portfolio/Login/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'Portfolio/Login/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'Portfolio/Login/FORGOT_PASSWORD_FAILED';

export const RESET_MESSAGE = 'Portfolio/Login/RESET_MESSAGE';
export const SIGNUP_RESET_MESSAGE = 'Portfolio/Login/SIGNUP_RESET_MESSAGE ';

export const VERIFICATION = 'Portfolio/Verification/VERIFICATION';
export const VERIFICATION_SUCCESS = 'Portfolio/Verification/VERIFICATION_SUCCESS';
export const VERIFICATION_FAILED = 'Portfolio/Verification/VERIFICATION_FAILED';
