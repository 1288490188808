import { GET_SERVICES, GET_SERVICES_SUCCESS } from './types';

export const getServices = () => ({
  type: GET_SERVICES,
});

export const getServicesSuccess = (data) => ({
  type: GET_SERVICES_SUCCESS,
  data,
});
