import {
  ADD_CARD_PACKAGE,
  ADD_CARD_PACKAGE_FAILED,
  GET_CARD_LIST,
  GET_CARD_LIST_FAILED,
  GET_CARD_LIST_SUCCESS,
  CARD_SELECTION,
  CARD_SELECTION_RESET,
} from './types';

const initialState = {
  requesting: false,
  cardList: false,
  addCardReq: false,
  cardSelectionRequesting: false,
  addCardMessage: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CARD_PACKAGE:
      return {
        ...state,
        addCardReq: true,
        addCardMessage: false,
      };

    case ADD_CARD_PACKAGE_FAILED:
      return {
        ...state,
        addCardReq: false,
        addCardMessage: action.data,
      };
    case GET_CARD_LIST:
      return {
        ...state,
        requesting: true,
        addCardMessage: false,
      };
    case GET_CARD_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        cardList: action.data,
      };
    case GET_CARD_LIST_FAILED:
      return {
        ...state,
        requesting: false,
      };
    case CARD_SELECTION:
      return {
        ...state,
        cardSelectionRequesting: true,
      };
    case CARD_SELECTION_RESET:
      return {
        ...state,
        cardSelectionRequesting: false,
      };

    default:
      return state;
  }
};
