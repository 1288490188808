import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';

import { push } from 'connected-react-router';
import toast, { Toaster } from 'react-hot-toast';

// utils
import XHR from '../../../utils/XHR';

// types
import {
  ADD_BUSINESS,
  DELETE_BUSINESS,
  EDIT_BUSINESS,
  GET_BUSINESS,
  GET_BUSINESS_BY_ID,
  FILTER_BUSINESS,
  SEARCH_BUSINESS,
  GET_CUISINES,
  UPLOAD_BULK_CSV,
  DOWNLOAD_CSV_SAMPLE,
  DELETE_SERVICES,
  UPDATE_PREFERED
} from './types';

//action
import {
  getBusinessSucces,
  getBusiness as getAllBusiness,
  getBusinessFailed,
  addBusinessFailed,
  getCuisinesSuccess,
  getBusinessByIdSuccess,
  uploadCSVBulkSuccess,
  uploadCSVBulkFailure,
  downloadCsv,
  downloadCsvSuccess,
} from './actions';

async function getBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/?page=${data}`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function getBusinessData() {
  const URL = `${BASE_URL}/business_data/`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}
async function addBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

async function deleteBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/${data.id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

async function getBusinessByIdAPI(data) {
  const URL = `${BASE_URL}/business_data/${data.id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function editBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/${data.id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data,
  };
  return XHR(URL, options);
}

async function searchBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/?search=${data}`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

async function filterBusinessAPI(data) {
  const URL = `${BASE_URL}/business_data/?category=${data.category}&state=${data.state}&city=${data.city}&zipcode=${data.zipcode}`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* filterBusiness({ data }) {
  try {
    const response = yield call(filterBusinessAPI, data);
    yield put(getBusinessSucces(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getBusinessFailed(response.data));
  }
}

function* searchBusiness({ data }) {
  try {
    const response = yield call(searchBusinessAPI, data);
    yield put(getBusinessSucces(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getBusinessFailed(response.data));
  }
}

function* addBusiness({ data }) {
  try {
    yield call(addBusinessAPI, data);
    yield put(
      push({
        pathname: '/business',
      })
    );
  } catch (e) {
    const { response } = e;
    // toast.error(`${response?.data?.facilities[0]?.icon[0] ? "Image: " + response?.data?.facilities[0]?.icon[0] : "Failed"}`)
    yield put(addBusinessFailed(response.data));
  }
}

function* editBusiness({ data }) {
  try {
    yield call(editBusinessAPI, data);
    yield put(
      push({
        pathname: '/business',
      })
    );
    sessionStorage.removeItem('editBusinesses');
  } catch (e) {
    const { response } = e;
    toast.error("Failed")
    yield put(getBusinessFailed(response.data));
  }
}

function* deleteBusiness({ data }) {
  try {
    yield call(deleteBusinessAPI, data);
    yield put(getAllBusiness(1));
  } catch (e) {
    const { response } = e;
  }
}

function* getBusinessById({ data }) {
  try {
    const response = yield call(getBusinessByIdAPI, data);
    sessionStorage.setItem('editBusinesses', JSON.stringify(response.data));
    yield delay(2000);
    yield put(getBusinessByIdSuccess());
    yield put(
      push({
        pathname: 'AddBusiness',
      })
    );
  } catch (e) {
    const { response } = e;
    yield put(getBusinessByIdSuccess(e));
  }
}

function* getBusiness({ data }) {
  try {
    const response = yield call(data ? getBusinessAPI : getBusinessData, data);
    yield put(getBusinessSucces(response.data));
  } catch (e) {
    const { response } = e;
    toast.error(response?.data?.message);
    // yield put(reset());
  }
}

async function getCuisinesAPI() {
  const URL = `${BASE_URL}/cuisines/`;
  const accessToken = await localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getCuisines() {
  try {
    const response = yield call(getCuisinesAPI);
    yield put(getCuisinesSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getCuisinesSuccess(response.data));
  }
}

async function uploadCSVAPI(data) {
  const URL = `${BASE_URL}/bulk/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* uploadCSV({ data }) {
  try {
    const response = yield call(uploadCSVAPI, data);
    yield put(uploadCSVBulkSuccess(response.data));
    toast.success(response?.data?.Message ? response?.data?.Message : 'Upload CSV Success');
  } catch (e) {
    const { response } = e;
    yield put(uploadCSVBulkFailure(response.data));
    toast.error(response?.data?.Message ? response?.data?.Message : 'Upload CSV Failed');
  }
}

async function downloadCSVAPI() {
  const URL = `${BASE_URL}/download/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* csvDownload() {
  try {

    const response = yield call(downloadCSVAPI);
    // const response = yield call(exportPlayerById, data.data);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.setAttribute('download', 'business_CSV.csv');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    yield put(downloadCsvSuccess(response?.data));


    // toast.success(response?.data?.Message ? response?.data?.Message : 'Upload CSV Success');
  } catch (e) {
    const { response } = e;
  }

  // yield put((response));
}

async function deletefacilityApi(data) {
  const URL = `${BASE_URL}/facility/${data}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}


function* deleteFacility({ data }) {
  try {
    yield call(deletefacilityApi, data);
    // yield put(ge);
  } catch (e) {
    const { response } = e;
  }
}


async function updatepreferredAPI(id, data) {
  const URL = `${BASE_URL}/business_preferred/${id}/`;
  const accessToken = await localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data
  };
  return XHR(URL, options);
}


function* updatepreferred({ id, data }) {
  try {
    yield call(updatepreferredAPI, id, data);
    yield put(getAllBusiness(1))
  } catch (e) {
    const { response } = e;
  }
}

export default all([
  takeLatest(GET_BUSINESS, getBusiness),
  takeLatest(ADD_BUSINESS, addBusiness),
  takeLatest(DELETE_BUSINESS, deleteBusiness),
  takeLatest(GET_BUSINESS_BY_ID, getBusinessById),
  takeLatest(EDIT_BUSINESS, editBusiness),
  takeLatest(SEARCH_BUSINESS, searchBusiness),
  takeLatest(FILTER_BUSINESS, filterBusiness),
  takeLatest(GET_CUISINES, getCuisines),
  takeLatest(UPLOAD_BULK_CSV, uploadCSV),
  takeLatest(DOWNLOAD_CSV_SAMPLE, csvDownload),
  takeLatest(DELETE_SERVICES, deleteFacility),
  takeLatest(UPDATE_PREFERED, updatepreferred),
]);
