import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter } from 'connected-react-router'

//Reducers
import login from '../Containers/Login/redux/reducer';
import business from '../Containers/Business/redux/reducer';
import services from '../Containers/AddBussines/redux/reducer';
import accountSetting from '../Containers/AccountSetting/redux/reducer'
import selectPackage from 'Containers/SelectPlan/redux/reducer';
import privacyPolicyAndTermsConditions from 'Containers/PrivacyPolicy/redux/reducer';
import cardPackage from '../Containers/BasicPackage/redux/reducer';
import events from '../Containers/Events/redux/reducer';
import stories from '../Containers/Stories/redux/reducer'


import addEvent from '../Containers/AddEvent/redux/reducer';
import addStory from '../Containers/AddStory/redux/reducer'


const signInPersistConfig = {
  key: 'login',
  storage,
  timeout: null
}

export const combinedReducers = history => ({
  login: persistReducer(signInPersistConfig, login),
  business,
  services,
  accountSetting,
  selectPackage,
  privacyPolicyAndTermsConditions,
  cardPackage,
  addEvent,
  events,
  stories,
  addStory
})
