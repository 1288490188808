import { ADD_EVENT_FAILURE, ADD_EVENT_REQUEST, ADD_EVENT_SUCCESS ,DELETE_EVENT_GALLARY, UPDATE_EVENTS,ADD_EVENT_GALLARY, GET_EVENT_KINDS, GET_EVENT_KINDS_SUCCESS} from './types';

export const addEventRequest = (data, eventsImage) => ({
  type: ADD_EVENT_REQUEST,
  data,
  eventsImage
});


export const addEventGallary = (data) => ({
  type: ADD_EVENT_GALLARY,
  data
});

export const deleteEventGallary = (id, eventId) => ({
  type: DELETE_EVENT_GALLARY,
  id,
  eventId
});

export const addEventSuccess = (data) => ({
  type: ADD_EVENT_SUCCESS,
  data
});

export const addEventFailure = (data) => ({
  type: ADD_EVENT_FAILURE,
  data
});


export const getEventsKinds = () => ({
  type: GET_EVENT_KINDS,
});

export const getEventsKindsSuccess = (data) => ({
  type: GET_EVENT_KINDS_SUCCESS,
  data
});

export const updateEvents = (data, id, addImageGallary) => ({
  type: UPDATE_EVENTS,
  data,
  id,
  addImageGallary
});


