import { all, call, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../../../config/app';

// utils
import XHR from '../../../utils/XHR';
import { push } from 'connected-react-router';

//Navigation

// types
import {
  ADD_STORY_REQUEST,
  UPDATE_STORY_REQUEST,
  ADD_STORY_GALLARY,
  GET_STORY_BY_ID_REQUEST,
  DELETE_STORY_GALLARY,
  GET_STORY_TYPE,
} from './types';
//action
import {
  addStorySuccess,
  addStoryFailure,
  getStoryByIdRequest,
  getStoryTypeSuccess,
} from './actions';

function getServicesAPI(data) {
  const URL = `${BASE_URL}/story/`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* addStoryRequest({ data, addStoryImages }) {
  try {
    const response = yield call(getServicesAPI, data);
    if (response.data) {
      if (addStoryImages) {
        addStoryImages(response.data.id);
      } else {
        yield put(addStorySuccess(response.data));
        yield put(
          push({
            pathname: '/admin/stories',
          })
        );
      }
    }
  } catch (e) {
    const { response } = e;
    yield put(addStoryFailure(response.data));
  }
}

function addStoryGallaryAPI(data) {
  const URL = `${BASE_URL}/story-gallery/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'POST',
    data,
  };
  return XHR(URL, options);
}

function* addStoryGallary({ data }) {
  try {
    const response = yield call(addStoryGallaryAPI, data);
    yield put(addStorySuccess(response.data));
    yield put(
      push({
        pathname: '/admin/stories',
      })
    );
  } catch (e) {
    const { response } = e;
    yield put(addStoryFailure(e.response));
  }
}

function updateStoryAPI(data, id) {
  debugger;
  const accessToken = localStorage.getItem('accessToken');
  const URL = `${BASE_URL}/story/${id}/`;
  const options = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Token ${accessToken}`,
    },
    method: 'PATCH',
    data,
  };
  return XHR(URL, options);
}

function* updateStory({ data, id, addImageGallary }) {
  try {
    const response = yield call(updateStoryAPI, data, id);
    if (response.data) {
      if (addImageGallary) {
        addImageGallary(response.data.id);
      } else {
        yield put(addStorySuccess(response.data));
        yield put(
          push({
            pathname: '/admin/stories',
          })
        );
      }
    }
  } catch (e) {
    const { response } = e;
    yield put(addStoryFailure(response?.data));
  }
}

function getStoryByIdAPI(id) {
  const URL = `${BASE_URL}/story/${id}/`;
  const accessToken = localStorage.getItem('accessToken');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getStoryById({ id }) {
  try {
    const response = yield call(getStoryByIdAPI, id);
    if (response.data) {
      sessionStorage.setItem('storyDetail', JSON.stringify(response.data));
      yield put(
        push({
          pathname: '/admin/addStory',
        })
      );
    }
  } catch (e) {
    const { response } = e;
    yield put(addStoryFailure(e.response));
  }
}

function deleteStoryGallaryAPI(id) {
  const URL = `${BASE_URL}/story-gallery/${id}/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

function* deleteStoryGallary({ id, eventId }) {
  try {
    const response = yield call(deleteStoryGallaryAPI, id);

    yield put(getStoryByIdRequest(eventId));
  } catch (e) {
    const { response } = e;
    yield put(addStoryFailure(e.response));
  }
}

function getStoryTypeAPI() {
  const URL = `${BASE_URL}/story-type/`;
  const accessToken = localStorage.getItem('accessToken');

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${accessToken}`,
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getStoryType() {
  try {
    const response = yield call(getStoryTypeAPI);

    yield put(getStoryTypeSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getStoryTypeSuccess(e.response));
  }
}

export default all([
  takeLatest(ADD_STORY_REQUEST, addStoryRequest),
  takeLatest(UPDATE_STORY_REQUEST, updateStory),
  takeLatest(ADD_STORY_GALLARY, addStoryGallary),
  takeLatest(GET_STORY_BY_ID_REQUEST, getStoryById),
  takeLatest(DELETE_STORY_GALLARY, deleteStoryGallary),
  takeLatest(GET_STORY_TYPE, getStoryType),
]);
