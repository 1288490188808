import {
  GET_BUSINESS,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUCCESS,
  UPDATE_BUSINESS,
  DELETE_BUSINESS,
  ADD_BUSINESS,
  EDIT_BUSINESS,
  GET_BUSINESS_BY_ID,
  SEARCH_BUSINESS,
  FILTER_BUSINESS,
  ADD_BUSINESS_FAILED,
  RESET_MESSAGE,
  GET_CUISINES,
  GET_CUISINES_SUCCESS,
  GET_BUSINESS_BY_ID_SUCCESS,
  UPLOAD_BULK_CSV,
  UPLOAD_BULK_CSV_SUCCESS,
  UPLOAD_BULK_CSV_FAILURE,
  DOWNLOAD_CSV_SAMPLE,
  DOWNLOAD_CSV_SAMPLE_SUCCESS,
  DELETE_SERVICES,
  DELETE_SERVICES_SUCCESS,
  UPDATE_PREFERED
} from './types';

export const getBusiness = (data) => ({
  type: GET_BUSINESS,
  data,
});

export const updatepreferred = (id, data) => ({
  type: UPDATE_PREFERED,
  id,
  data,
});

export const getBusinessFailed = (data) => ({
  type: GET_BUSINESS_FAILED,
  data,
});

export const getBusinessSucces = (data) => ({
  type: GET_BUSINESS_SUCCESS,
  data,
});

export const getBusinessById = (data) => ({
  type: GET_BUSINESS_BY_ID,
  data,
});

export const getBusinessByIdSuccess = () => ({
  type: GET_BUSINESS_BY_ID_SUCCESS,
});

export const updateBusiness = (data) => ({
  type: UPDATE_BUSINESS,
  data,
});

export const deleteBusiness = (data) => ({
  type: DELETE_BUSINESS,
  data,
});

export const addBusiness = (data) => ({
  type: ADD_BUSINESS,
  data,
});

export const addBusinessFailed = (data) => ({
  type: ADD_BUSINESS_FAILED,
  data,
});

export const editBusiness = (data) => ({
  type: EDIT_BUSINESS,
  data,
});

export const searchBusiness = (data) => ({
  type: SEARCH_BUSINESS,
  data,
});

export const filterBusiness = (data) => ({
  type: FILTER_BUSINESS,
  data,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
});

export const getCuisines = () => ({
  type: GET_CUISINES,
});

export const getCuisinesSuccess = (data) => ({
  type: GET_CUISINES_SUCCESS,
  data,
});

export const uploadBulkCSV = (data) => ({
  type: UPLOAD_BULK_CSV,
  data,
});

export const uploadCSVBulkSuccess = (data) => ({
  type: UPLOAD_BULK_CSV_SUCCESS,
  data,
});

export const uploadCSVBulkFailure = (data) => ({
  type: UPLOAD_BULK_CSV_FAILURE,
  data,
});
export const downloadCsv = () => ({
  type: DOWNLOAD_CSV_SAMPLE,
});
export const downloadCsvSuccess = (data) => ({
  type: DOWNLOAD_CSV_SAMPLE_SUCCESS,
  data,
});
export const deleteServices = (data) => ({
  type: DELETE_SERVICES,
  data,
});
export const deleteServicesSuccess = (data) => ({
  type: DELETE_SERVICES_SUCCESS,
  data,
});