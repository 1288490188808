import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNUP,
  LOGIN_FAILED,
  RESET_MESSAGE,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  VERIFICATION,
  VERIFICATION_FAILED,
  VERIFICATION_SUCCESS,
  UPDATE_PASSWORD_RESET,
  SIGNUP_RESET_MESSAGE,
  PROFILE_PASSWORD,
} from './types';

export const signin = (data) => ({
  type: SIGNIN,
  data,
});

export const signinSuccess = (data) => ({
  type: SIGNIN_SUCCESS,
  data,
});

export const loginFailed = (data) => ({
  type: LOGIN_FAILED,
  data,
});

export const signup = (data) => ({
  type: SIGNUP,
  data,
});

export const signupSuccess = (data) => ({
  type: SIGNUP_SUCCESS,
  data,
});

export const signupFailed = (data) => ({
  type: SIGNUP_FAILED,
  data,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
});
export const signupResetMessage = () => ({
  type: SIGNUP_RESET_MESSAGE,
});

export const updatePassword = (data) => ({
  type: UPDATE_PASSWORD,
  data
});
export const profilePassword = (data, resetValue) => ({
  type: PROFILE_PASSWORD,
  data,
  resetValue
});

export const updatePasswordSuccess = (data) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  data,
});
export const updatePasswordFailed = (data) => ({
  type: UPDATE_PASSWORD_FAILED,
  data,
});

export const updatePasswordReset = () => ({
  type: UPDATE_PASSWORD_RESET
})

export const verification = (data) => ({
  type: VERIFICATION,
  data
});
export const verificationSuccess = () => ({
  type: VERIFICATION_SUCCESS,
});

export const verificationFailed = (data) => ({
  type: VERIFICATION_FAILED,
  data,
});



export const forgetPassword = (data) => ({
  type: FORGOT_PASSWORD,
  data
});
export const forgetPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgetPasswordFailed = (data) => ({
  type: FORGOT_PASSWORD_FAILED,
  data,
});
