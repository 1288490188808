import {
  GET_TERMS_CONDITIONS,
  GET_PRIVACY_POLICY,
  GET_TERMS_CONDITIONS_SUCCESS,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_AND_TERMS_CONDITIONS_FAILED
} from './types';

const initialState = {
  requesting: false,
  backendErrors: false,
  termsConditions:false,
  privacyPolicy:false

};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_TERMS_CONDITIONS:
      return {
        ...state,
        requesting: true,
      };
      
      case GET_PRIVACY_POLICY:
      return {
        ...state,
        requesting: true,
      };

    case GET_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        requesting: false,
        termsConditions: action.data,
      };

      case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        requesting: false,
        privacyPolicy: action.data,
      };

    case GET_PRIVACY_POLICY_AND_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        requesting: false,
        backendErrors: action.error,
      };

    default:
      return state;
  }
};
